section.home {
  color: #fff;

  .user-groups {
    background: #fff;
    margin: $padded-more 0;
    transform:skew(0deg, 1deg);
    padding: $padded-more 0;
    .container-fluid {
      transform:skew(0deg, -1deg);
    }
    .user-group {
      padding: $padded-more;
      margin: 0;
      cursor: pointer;
      background: #fff;
      color: $ivans-midnight;
      h1 {
        margin: 0;
        padding: 0;
        span {
          display: block;
          font-size: 18px;
          color: $ivans-fern;
        }
      }
      &.even {
        border-right: 4px solid $gray-lighter;
        border-left: 4px solid $gray-lighter;
      }
    }
  }

}
@media (max-width: $screen-sm-min) {
  section.home {
    .user-groups {
      .user-group {
        h1 {
          text-align: center;
        }
      }
    }
    .banner {
      .headline {
        font-size: 1.75em;
        height: 140px;
        overflow: hidden;
      }
    }
  }
}
