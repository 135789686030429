section.faq {
  color: #fff;
  .headline {
    text-align: left;
    margin: 0;
    padding: $padded-more 0;
    h1,h3 {
      margin: 0,
      padding 0
    }
  }
  section {
    padding: $padded-more;
  }
  form {
    margin: 0;
  }
  p.lead {
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
  .art {
    text-align: center;
    img {
      display: inline-block;
      max-height: 250px;
    }
  }
  .questions {
    background: #fff;
    color: $ak-navy;
    .question {
      padding: $padded * 1.5;
      p,ul {
        color: darken($gray, 10%);
        font-size: 1.1em;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  .conclusion {

    .headline {
      padding: $padded-more 0;
      text-align: center;

      h1, h4 {
        margin: 0;
        padding: 0;
      }

    }
  }


}
