@font-face {
    font-family: 'amino-black';
    src: url('#{$font-path}/#{$amino-black-obfuscated}/#{$amino-black-obfuscated}.eot');
    src: url('#{$font-path}/#{$amino-black-obfuscated}/#{$amino-black-obfuscated}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/#{$amino-black-obfuscated}/#{$amino-black-obfuscated}.woff') format('woff'),
         url('#{$font-path}/#{$amino-black-obfuscated}/#{$amino-black-obfuscated}.ttf') format('truetype');
}

@font-face {
    font-family: 'brandon-text-light';
    src: url('#{$font-path}/#{$brandon-text-light-obfuscated}/#{$brandon-text-light-obfuscated}.eot');
    src: url('#{$font-path}/#{$brandon-text-light-obfuscated}/#{$brandon-text-light-obfuscated}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/#{$brandon-text-light-obfuscated}/#{$brandon-text-light-obfuscated}.woff') format('woff'),
         url('#{$font-path}/#{$brandon-text-light-obfuscated}/#{$brandon-text-light-obfuscated}.ttf') format('truetype');
}

@font-face {
    font-family: 'brandon-text-regular';
    src: url('#{$font-path}/#{$brandon-text-regular-obfuscated}/#{$brandon-text-regular-obfuscated}.eot');
    src: url('#{$font-path}/#{$brandon-text-regular-obfuscated}/#{$brandon-text-regular-obfuscated}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/#{$brandon-text-regular-obfuscated}/#{$brandon-text-regular-obfuscated}.woff') format('woff'),
         url('#{$font-path}/#{$brandon-text-regular-obfuscated}/#{$brandon-text-regular-obfuscated}.ttf') format('truetype');
}

@font-face {
    font-family: 'brandon-text-medium';
    src: url('#{$font-path}/#{$brandon-text-medium-obfuscated}/#{$brandon-text-medium-obfuscated}.eot');
    src: url('#{$font-path}/#{$brandon-text-medium-obfuscated}/#{$brandon-text-medium-obfuscated}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/#{$brandon-text-medium-obfuscated}/#{$brandon-text-medium-obfuscated}.woff') format('woff'),
         url('#{$font-path}/#{$brandon-text-medium-obfuscated}/#{$brandon-text-medium-obfuscated}.ttf') format('truetype');
}

@font-face {
    font-family: 'brandon-text-bold';
    src: url('#{$font-path}/#{$brandon-text-bold-obfuscated}/#{$brandon-text-bold-obfuscated}.eot');
    src: url('#{$font-path}/#{$brandon-text-bold-obfuscated}/#{$brandon-text-bold-obfuscated}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/#{$brandon-text-bold-obfuscated}/#{$brandon-text-bold-obfuscated}.woff') format('woff'),
         url('#{$font-path}/#{$brandon-text-bold-obfuscated}/#{$brandon-text-bold-obfuscated}.ttf') format('truetype');
}
