footer {
  color: #fff;
  padding: $padded-more*2 $padded;
  hr {
    border-color: $ivans-forest;
  }

  .color-dot {
    border-radius: 50%;
    display:inline-block;
    width:10px;
    height:10px;
    margin-right:5px;
    border: 1px solid #fff;
    &.critical {
      background-color: $ak-red;
    }
    &.major {
      background-color: $ak-orange;
    }
    &.minor {
      background-color: $ak-yellow;
    }
    &.none {
      background-color: $ak-green;
    }
  }

  h1, h2, h3, h4, h5 {
    color: inherit;
  }
  .ivans-logo {
    max-width: 200px;
  }
  a {
    color: #fff;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
  .col {
    min-height: 275px;
    padding: 0 $padded-more;
    font-size: 1.3em;
    border-right: 4px solid $ivans-fern;
    h3 {
      padding-bottom: $padded-less;
    }
    &.contact {
      .btn {
        margin-bottom: 30px;
      }
      address {
        font-size: 16px;
      }
    }
    &.company {
      a {
        color: #fff;
      }
    }
    &.connect {
      i {
        font-size: 3.5em;
        margin-right: $padded-more;
      }

    }
  }
  .col:last-of-type {
    border-right: 0;
  }
  .disclaimers {
    text-align: center;
    padding: $padded-more;
    .system-status {
      &.not-loaded {
        display: none;
      }
    }
    .small {
      opacity: .65;
      padding: 10px 0;
    }
  }
}
@media (max-width: $screen-sm-min) {
  footer {
    .col {
      border-right: 0;
      padding: $padded-less;
      min-height: auto;
      border-bottom: 4px solid $ivans-fern;
      text-align: center;
    }
  }
}
