body {
  &.full-page {
    padding: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    nav.menu {
      display: none;
    }
    footer {
      display: none;
    }
    div.main {
      display: table;
      position: relative;
      width: 100%;
      height: 100%;
    }
    section {
      display: table-cell;
      width: 50%;
      height: 100%;
      margin: 0;
    }
  }
}
@media (max-width: $screen-sm-min) {
  body {
    &.full-page {
      height: auto;
      div.main {
        display: block;
      }
      section {
        display: block;
        width: auto;
      }
    }
  }
}
