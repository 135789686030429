section {
  margin: 0;
  padding: ($padded-more * 2) 0;
  a {
    font-family: $font-family-medium;
  }
  p {
    font-size: $font-size-large;
    a {
      text-decoration: underline;
    }
  }
  &:first-of-type {
    margin-top: 0;
  }
  &.white {
    background: #fff;
    color: $text-color;
    .btn-trans {
      color: inherit;
      border-color: darken($gray,5%);
    }
    hr {
      border-color: $gray-lighter;
    }
    &.no-shadow {
      box-shadow: none;
    }
  }
  &.transparent {
    color: #fff;
    .btn-trans {
      color: inherit;
      border-color: darken($gray,5%);
    }
    a {
      color: inherit;
    }
    hr {
      border-color: $gray-lighter;
    }
  }
  &.skew-left {
    margin: -($padded-more) 0 0;
    padding: ($padded-more * 3) 0 ($padded-more * 4);
    transform:skew(0deg,-2deg);
    .container-fluid {
      transform:skew(0deg,2deg);
    }
  }
  &.skew-right {
    margin: -($padded-more) 0 0;
    padding: ($padded-more * 3) 0 ($padded-more * 4);
    transform:skew(0deg,2deg);
    .container-fluid {
      transform:skew(0deg,-2deg);
    }
  }
  .actions {
    a {
      color: inherit;
    }
    .btn {
      border-color: darken($ivans-cantaloupe, 10%);
      background: $ivans-cantaloupe;
      color: #fff;
      &:hover {
        background: lighten($ivans-cantaloupe, 5%);
        color: #fff;
      }
    }
  }
}
