div.sign-up {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  h1, h2 {
    padding: $padded-less 0;
    margin: 0;
  }
  section {
    padding: $padded;
    vertical-align: top;
    &.teal {
      background: $ivans-fern;
      color: #fff;
      .btn {
        border-color: #fff;
      }
    }
    &.purple {
      background: $ivans-indigo;
      color: #fff;
      .btn {
        color: #fff;
        border-color: #fff;
      }
    }
    &.description {
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 75%;
      padding-bottom: 220px;
      h3 {
        margin: $padded-more 0;
      }
      a {
        color: inherit;
        text-decoration: underline;
      }
      &.carrier {
        background-image: url("../etc/img/characters_carrier.png");
      }
      &.agent {
        background-image: url("../etc/img/characters_agent.png");
      }
      &.wholesaler {
        background-image: url("../etc/img/characters_wholesaler.png");
      }
    }
    &.form {
      h2 {
        margin: $padded 0;
        padding: 0;
      }
      form {
        a {
          text-decoration: underline;
        }
        .help-block {
          color: $gray;
          font-style: normal;
          font-size: 1.2em;
          padding: 15px 0;
        }
      }
      .group-logo {
        border-bottom: 1px solid $gray-lighter;
        padding: $padded;
        margin-bottom: $padded-more;
        height: 150px;
        text-align: center;
        overflow: hidden;
        position: relative;
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
    .success {
      text-align: center;
      a {
        text-decoration: underline;
      }
    }
    .what-next {
      padding: 20px 0;
      font-size: 1.1em;
    }
    .fine-print {
      margin-top: 50px;
      font-size: 0.9em;
      opacity: 0.8;
    }
  }
  a.group-button {
    display: block;
    color: inherit;
    border: 1px solid $gray-light;
    border-radius: $border-radius-small;
    padding: $padded-less;
    background: darken(#fff, 2%);
    cursor: pointer;
    text-decoration: none;
    &:hover {
      border-color: $gray;
    }
    h3 {
      font-family: $font-family-regular;
      margin: 0;
      padding: 0;
    }
    p {
      height: 70px;
      font-size: 1.2em;
    }
  }
}
@media (max-width: $screen-sm-min) {
  div.sign-up {
    display: block;
    section {
      &.form {
        form {
          margin-top: 0;
        }
      }
    }
    h1 {
      font-size: 22px;
      padding: $padded-more 0;
    }
    h2 {
      font-size: 18px;
    }
    a.group-button {
      text-align: center;
      h3 {
        padding: $padded 0;
      }
      margin: $padded 0;
    }
  }
}
