.btn-trans {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  &:hover {
    background: rgba(255,255,255,.1);
    border-color: #fff;
    color: #fff;
  }

}
