html,
body {
  height: 100%;
  width: 100%;
}
body {
  padding-top: $nav-base-height;
  //transition: background-color 200ms linear;
  //background-image: linear-gradient( 30deg, lighten($body-bg, 3%), $body-bg);
  //background-attachment: fixed;

}
strong {
  font-family: $font-family-regular;
  font-weight: bold;
}
h1, h2, h3, h4, h5 {
  font-family: $font-family-medium;
  text-transform: capitalize;
}
