nav.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent;
  background-image: none;
  z-index: 10;
  height: $nav-base-height;
  line-height: $nav-base-height;
  color: #fff;
  font-size: $nav-base-height * .18;
  padding-left: 0;
  transition: background-color, background-image, padding-left, color,height,line-height,font-size 300ms;
  &.scrolled {
    background-image: url("../etc/img/askkodiak-logo.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    background-color: rgba($ivans-midnight,.9);
    padding-left: 70px;
    color: #fff;
    height: $nav-base-height * .75;
    line-height: $nav-base-height * .75;
    font-size: $nav-base-height * .15;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    &:hover {
      background-color: rgba($ivans-midnight,1);
    }
    a {
      &.action {
        &.default {
          border-color: $ak-blue;
        }
      }
      &.primary {
        border: 1px solid darken($ivans-cantaloupe, 10%);

        background: $ivans-cantaloupe;
        color: #fff;
      }
    }
    h1 {
      line-height: $nav-base-height * .8;
      font-size: $nav-base-height * .25;
    }
  }
  h1, h4 {
    display: inline-block;
    color: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0 10px;
    line-height: inherit;
    position: relative;
    font-family: $font-family-base;
    &.active {
      font-family: $font-family-black;
    }
  }
  li {
    padding: 0 10px;
  }
  a {
    color: inherit;
    &:hover {
      text-decoration: inherit;
    }
    &:active {
      text-decoration: inherit;
    }
    &:link {
      text-decoration: inherit;
    }
    &.active{
      text-decoration: inherit;
    }
    &.action {
      padding: 10px;
      color: inherit;
      font-family: $font-family-medium;
      border-radius: $border-radius-small;
    }
    &.primary {
      background: transparent;
      border: 2px solid #fff;
      color: #fff;
    }
    &.default {
      border: 3px solid rgba(255,255,255,.6);
      cursor: pointer;
    }
  }
}
