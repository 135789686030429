.modal {
  .modal-content {
    box-shadow: none;
  }
  h1.modal-title {
    font-family: 'amino-black';
  }

  ul.navigation {
    li {
      padding-bottom: 20px;
      &.sign-in {
        padding: 20px 0;
        text-align: right;
        font-size: 1.5em;
      }
    }
  }
  .menu-modal {
    .modal-title {
      font-size: 28px;
    }
  }
  .sign-up {
    margin-top: 40px;
    h3 {
      margin-bottom: 20px;
    }
    .actions {
      margin-top: 20px;
    }
  }

  .get-a-demo {
    .screenshot {
      margin: 0 $padded;
    }

  }
}

