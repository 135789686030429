@import "variables";

/* 3rd Party SCSS -----------------------------*/
@import "bootstrap"; // core front-end framework used by AskKodiak
@import "fontawesome"; // scalable vector icons for AskKodiak
@import "regular";// 400 weight font-awesome icons.
@import "solid";// 900 weight font-awesome icons.
@import "light";// 300 weight font-awesome icons.
@import "brands";// fontawesome brand icons

/* AskKodiak SCSS -----------------------------*/
@import "fonts"; // Defines font-faces used in AskKodiak

@import "typed";

@import "html-elements"; //Any AskKodiak default CSS applied to HTML elements
@import "layout";
@import "helpers"; //reusable helper classes
@import "menu";
@import "sections";

@import "buttons";
@import "modals";
@import "popover";
@import "forms";

@import "home";

@import "group"; //shared by agents/carriers/wholesalers
@import "agents";
@import "carriers";
@import "wholesalers";

@import "faq";
@import "sign-up";
@import "legal";
@import "footer";
