/* AskKodiak Variables -------------------------------*/

/* AskKodiak Paths */
$font-path: '../fonts';
$fa-font-path: '../fonts'; //font-awesome
$icon-font-path: '../fonts'; //bootstrap

$amino-black-obfuscated: '2F3524';

$brandon-text-light-obfuscated: '312D82_1_0';
$brandon-text-regular-obfuscated: '312D82_2_0';
$brandon-text-medium-obfuscated: '312D82_3_0';
$brandon-text-bold-obfuscated: '312D82_0_0';

$fa-font-path: '../fonts';

$section-tilt: -2deg;

/* AskKodiak Colors */
$ak-teal: #f6f9fc;
$ak-blue: #21b2fe;
$ak-blue-dark: #0C78B1;
$ak-blue-bright: #3BBBFF;
$ak-blue-gray: #10597E;
$ak-orange: #FE9D21;
$ak-orange: #F39C12;
$ak-brown: rgb(117,72,30);
$ak-yellow: rgb(250,196,8);
$ak-purple: rgb(84,96,174);
$ak-green: #8bc34a;
$ak-navy: #082C3F;
$ak-red: #E74C3C;

// https://brandpad.io/ivans/
$ivans-midnight: #071F30;
$ivans-forest: #016756;
$ivans-indigo: #4B0081;
$ivans-fern: #3AB46D;
$ivans-cerulean: #0492C2;
$ivans-cantaloupe: #F97E58;

$gray-base: #000;
$gray-darker: lighten($gray-base, 10%);
$gray-dark: lighten($gray-base, 20%);
$gray: lighten($gray-base, 60%);
$gray-light: lighten($gray-base, 75%);
$gray-lighter: lighten($gray-base, 95%);

/* AskKodiak Misc */
$padded: 20px;
$padded-less: $padded * 0.7;
$padded-more: $padded * 2.2;

$nav-base-height: 100px;

$ak-main-menu-width: 170px;

$cloud-color: #fff;
$cloud-height-base: 120px;
$cloud-width-base: 350px;

/* Bootstrap Modifying Variables ----------------------*/

// Typography
$text-color: $ivans-midnight;

$font-family-base: 'brandon-text-light', -apple-system, '.SFNSText-Regular', 'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;
$font-family-medium: 'brandon-text-medium';
$font-family-regular: 'brandon-text-regular';
$font-family-bold: 'brandon-text-bold';
$font-family-black: 'brandon-text-bold';
$askkodiak-wordmark-font: 'amino-black';
$headings-font-family: $font-family-bold;

$font-size-base: 15px;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
//$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$headings-color: inherit;

// Colors
$body-bg:               $ivans-cerulean;
$brand-primary:         $ivans-cerulean;
$brand-success:         $ivans-fern;
$brand-info:            $ivans-forest;
$brand-warning:         $ivans-cantaloupe;
$brand-danger:          $ivans-midnight;

// Bootstrap Grid
$grid-gutter-width: 10px;

// Round Corners are so 2003
$border-radius-base:        2px;
$border-radius-large:       6px;
$border-radius-small:       4px;

//navbar
/*
$navbar-bg: red;
$navbar-height: 100px;
*/
$popover-bg: $ak-blue;
$popover-border-color: lighten($ak-blue, 10%);

//** Background color of modal content area
$modal-content-bg:  transparent;
//** Modal content border color
$modal-content-border-color: transparent;

//** Modal backdrop background color
$modal-backdrop-bg: #fff;
//** Modal backdrop opacity
$modal-backdrop-opacity: 1;
//** Modal header border color
$modal-header-border-color: transparent;

$btn-default-color: $ak-blue;
$btn-info-color: $ak-blue;
$btn-info-border: darken($ak-teal, 15%);


