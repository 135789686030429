.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.padded {
  padding: $padded;
}
.padded-more {
  padding: $padded-more;
}
.padded-less {
  padding: $padded-less;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pb-4 {
  padding-bottom: 4rem;
}
.wordmark {
  white-space: nowrap; // do not wrap between words
  font-family: $askkodiak-wordmark-font;
  letter-spacing: -1px;
  .prefix {
    opacity: .7;
  }
}
.banner {
  color: #fff;
  padding: $padded-more $padded-more ($padded-more * 3);
  text-align: center;

  .headline {
    padding-top: $padded-more;
    font-size: 2.35em;
    text-transform: capitalize;
  }
  .subtext {
    font-size: 1.3em;
    padding: $padded 0;

    color: darken(#fff,3%);
  }
}
.footnote {
  font-size: .9em;
  padding: $padded-more 0;
  font-style: italic;
}
@media (max-width: $screen-sm-min) {
  .banner {
    text-align: left;
    .headline {
      font-size: 1.75em;
      margin: $padded 0;
      padding: 0;
    }
    .subtext {
      margin: $padded 0;
      font-size: 1.2em;
    }
  }
}

