form {
  .ng-invalid {
    &.ng-touched {
      border-color: $ivans-cantaloupe;
    }
  }
  input, textarea {
    &.form-control {
      color: $gray-darker;
    }
  }
  button[disabled="disabled"] {
    color: $gray-light;
  }
  .help-block {
    font-size: .9em;
    line-height: 1.4em;
    color: inherit;
    padding: 5px;
    font-style: italic;
  }
  .error-message {
    color: $ivans-cantaloupe;
    font-family: $font-family-bold;
    font-size: .8em;
  }
}
