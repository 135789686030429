section.group {
  .benefit {
    padding: $padded-more * 3 $padded-more;
    .screenshot {
      text-align: center;
      margin: 0 $padded-more;
      img {
        display: inline-block;
        max-height: 400px;
      }
    }
    .video {
      margin: $padded-more 0;
    }
  }
  .actions {
    padding: $padded-more*2 $padded-more;
    .primary-action {
      padding: $padded-more;
      text-align: center;
      .btn {
        padding: $padded;
        font-family: $font-family-medium;
        text-transform: uppercase;
        font-size: 25px;
      }
    }
    .secondary-action {
      padding: 0 $padded;
      ul {
        font-family: $font-family-regular;
        font-size: 1.7em;
        li {
          border-top: 1px solid;
          padding: 10px 0;
          &:first-of-type {
            border-top: 0;
          }
        }
      }

    }
  }
  .testimonials {
    background: #fff;
    .testimonial {
      padding: $padded-more;
      margin: $padded;
      border-radius: $border-radius-large * 2;
      box-shadow: 0 0 50px $gray-light;
      background: darken(#fff, 1%);
      color: darken($gray,5%);
      p {
        i {
          display: inline-block;
          margin-right: $padded-less;
        }
      }
      .citation {
        padding: $padded 0;
        .thumb {
          display: inline-block;
          float: right;
          border-radius: 50%;
          position: relative;
          height: 80px;
          width: 80px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .who {
          font-family: $font-family-medium;
          color: $ivans-indigo
        }
        .from {
          color: $ivans-fern;
          a {
            color: inherit;
          }
        }
      }
    }
  }
}
@media (max-width: $screen-sm-min) {
  section.group {
    .benefit {
      .screenshot {
        margin: $padded;
      }
    }
    .actions {
      padding: $padded-more 0;
      .primary-action {
        padding: $padded-more 0;
        .btn {
          padding: $padded $padded-less;
          font-size: 18px;
        }
      }
      .secondary-action {
        text-align: center;
        font-size: .8em;
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style-type: none;
            margin: $padded-less 0;
          }
        }

      }
    }
    .testimonials {
      padding: 0;
      .testimonial {
        padding: $padded;
        margin: $padded-more 0;
      }
    }
  }
}
